// Selected Prisma enums. These are extracted manually from @prisma/client.
//
// This avoids having to import the entire client into our frontend pages,
// saving 13kb gzipped for many endpoints. Unfortunately, we now have to
// maintain this file and keep it in sync with the client.
//
// Please keep this sorted alphabetically for easier maintenance.

export const auth_channel = {
  SMS: "SMS",
  WHATSAPP: "WHATSAPP",
} as const;

export type auth_channel = (typeof auth_channel)[keyof typeof auth_channel];

export const event_type = {
  auth_challenge_create: "auth_challenge_create",
  auth_challenge_pass: "auth_challenge_pass",
  card_submit: "card_submit",
  module_begin: "module_begin",
  module_complete: "module_complete",
  course_complete: "course_complete",
} as const;

export type event_type = (typeof event_type)[keyof typeof event_type];

export const message_channel = {
  SMS: "SMS",
  SMS_OTP: "SMS_OTP",
  WHATSAPP: "WHATSAPP",
  WHATSAPP_OTP: "WHATSAPP_OTP",
  CONSOLE: "CONSOLE",
} as const;

export type message_channel =
  (typeof message_channel)[keyof typeof message_channel];

export const message_key = {
  INBOUND: "INBOUND",
  AUTO_REPLY_OTP: "AUTO_REPLY_OTP",
  AUTO_REPLY_FEEDBACK: "AUTO_REPLY_FEEDBACK",
  SEND_OTP: "SEND_OTP",
  PLAIN: "PLAIN",
  INITIATE_SUPPORT: "INITIATE_SUPPORT",
  CONTINUE_SUPPORT: "CONTINUE_SUPPORT",
  NOTIFICATION_COURSE_REMINDER_ONE_DAY: "NOTIFICATION_COURSE_REMINDER_ONE_DAY",
} as const;

export type message_key = (typeof message_key)[keyof typeof message_key];

export const message_status = {
  DRAFT: "DRAFT",
  FATAL: "FATAL",
  QUEUED: "QUEUED",
  SENT: "SENT",
  DELIVERED: "DELIVERED",
  FAILED: "FAILED",
  UNKNOWN: "UNKNOWN",
  READ: "READ",
  WARNING: "WARNING",
  DELETED: "DELETED",
  HELD_FOR_QUALITY_ASSESSMENT: "HELD_FOR_QUALITY_ASSESSMENT",
  RECEIVING: "RECEIVING",
  RECEIVED: "RECEIVED",
  UNDELIVERED: "UNDELIVERED",
  SENDING: "SENDING",
} as const;

export type message_status =
  (typeof message_status)[keyof typeof message_status];

export const name_change_request_status = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
} as const;

export type name_change_request_status =
  (typeof name_change_request_status)[keyof typeof name_change_request_status];

export const tag_type = {
  SIGNUP: "SIGNUP",
  LANDING_PAGE: "LANDING_PAGE",
  UTM_SOURCE: "UTM_SOURCE",
  UTM_MEDIUM: "UTM_MEDIUM",
  UTM_CAMPAIGN: "UTM_CAMPAIGN",
  UTM_CONTENT: "UTM_CONTENT",
  UTM_TERM: "UTM_TERM",
  COURSE: "COURSE",
} as const;

export type tag_type = (typeof tag_type)[keyof typeof tag_type];

export const event_meta_rushed = {
  OK_AUTO: "OK_AUTO",
  RUSHED_AUTO: "RUSHED_AUTO",
  OK_OVERRIDE: "OK_OVERRIDE",
  RUSHED_OVERRIDE: "RUSHED_OVERRIDE",
} as const;

export type event_meta_rushed =
  (typeof event_meta_rushed)[keyof typeof event_meta_rushed];

export const role = {
  authenticated: "authenticated",
  registered: "registered",
  qualityAssurance: "qualityAssurance",
  moderator: "moderator",
  admin: "admin",
} as const;

export type role = (typeof role)[keyof typeof role];
