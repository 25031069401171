"use client";

import React, { useCallback, useContext, useEffect, useState } from "react";

import * as Sentry from "@sentry/nextjs";

import type { UserEntity } from "@/hl-common/types/api/entities/Users";

import Message from "@/components/Message";
import { Button } from "@/components/forms/Button";

import type { API } from "@/hl-common/types/api/API";
import { auth_channel } from "@/hl-common/types/api/PrismaEnums";
import type { role } from "@prisma/client";
import { useRouter } from "next/navigation";
import type { UserTokenV2 } from "../hl-common/types/UserToken";
import { ModalContext } from "./ModalContext";
import {
  logout,
  me,
  registerUser,
  verifyOtp,
  verifyPassword,
} from "./api/client";
import {
  ApiError,
  getErrorMessage,
  isInvalidCredentialError,
} from "./api/fetch";

export const userContextInitialValue = {
  loadUser: async () => {},
  user: {} as UserEntity,
  userLoading: false,
  handleUserRegister: async (user: API.registerUser.body) => {
    return {} as API.registerUser.resp;
  },
  handleLogin: async (body: API.verifyOtp.body) => {
    return {} as API.verifyOtp.resp;
  },
  handlePassword: async (body: API.verifyPassword.body) => {
    return {} as API.verifyPassword.resp;
  },
  handleLogout: () => {},
};

export const UserContext = React.createContext(userContextInitialValue);

// prefill some empty user properties to avoid undefined errors e.g. on logout
const emptyUser: UserEntity = {
  id: 0,
  username: "",
  first: "",
  last: "",
  email: "",
  phone: "",
  provider: "",
  confirmed: false,
  blocked: false,
  createdAt: "",
  updatedAt: "",
  termsAndConditions: false,
  authChannel: auth_channel.WHATSAPP,
  role: null as unknown as role,
};

export const WithUser = ({
  serverSideUser,
  children,
}: { serverSideUser: UserTokenV2 | null; children: React.ReactNode }) => {
  const { replace } = useRouter();
  const { setModal } = useContext(ModalContext);
  const [user, setUser] = useState(emptyUser);
  const [userLoading, setUserLoading] = useState(false);

  const handleUserRegister = useCallback(
    async (body: API.registerUser.body) => {
      const resp = await registerUser({ body });
      setUser(resp.data);
      return resp;
    },
    [],
  );

  const handleLogin = useCallback(async (body: API.verifyOtp.body) => {
    const resp = await verifyOtp({ body });
    setUser(resp.data.user);
    return resp;
  }, []);

  const handlePassword = useCallback(async (body: API.verifyPassword.body) => {
    const resp = await verifyPassword({ body });
    setUser(resp.data.user);
    return resp;
  }, []);

  const handleLogout = useCallback(async () => {
    const resp = await logout();
    if (resp.success) {
      // force full refresh on logout to avoid stale layouts
      // note: useRouter().refresh() is insufficient to clear the client-side router cache!
      window.location.href = "/login";
    }
  }, []);

  const loadUser = useCallback(async () => {
    setUserLoading(true);

    try {
      const { data: user } = await me();
      setUser(user);
    } catch (error) {
      if (
        error instanceof ApiError &&
        (error.statusCode === 401 || // nestjs anon
          error.statusCode === 403 || // sentry anon
          isInvalidCredentialError(error)) // expired/malformed token
      ) {
        // swallow the expected errors
      } else {
        // Show an error, but don't reset `user`.
        setModal(<UserError error={error} />);
      }
    }

    setUserLoading(false);
  }, [setModal]);

  // fetch the user on mount, if the server parsed their cookie
  // TODO: maybe do this fetch from next backend?
  useEffect(() => {
    if (serverSideUser) {
      loadUser();
    }
  }, [serverSideUser, loadUser]);

  // set user in sentry scope
  useEffect(() => {
    const scope = Sentry.getCurrentScope();
    if (user.id) {
      scope.setUser({
        id: user.id,
      });
    } else {
      scope.setUser(null);
    }
  }, [user.id]);

  return (
    <UserContext.Provider
      value={{
        loadUser,
        user,
        userLoading,
        handleUserRegister,
        handleLogin,
        handlePassword,
        handleLogout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const UserError = ({ error }: { error: unknown }) => {
  const { close } = useContext(ModalContext);
  return (
    <>
      <Message error>Error authenticating: {getErrorMessage(error)}</Message>
      <Button secondary onClick={close}>
        Close
      </Button>
    </>
  );
};
